import React, { useState, useCallback, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";

import { List, TextField, Box, InputAdornment } from "@material-ui/core";
import { FilterList as FilterListIcon } from "@material-ui/icons";

import { useHotkeys } from "react-hotkeys-hook";

import TocGroup from "./group";

const ApiDocToc = ({ location }) => {
	const [value, setValue] = useState(location?.state?.filterValue || "");
	const onChange = useCallback(e => {
		setValue(e.target.value);
	}, []);

	const inputRef = useRef();

	useHotkeys(
		"/",
		e => {
			if (document.activeElement !== inputRef.current) {
				e.preventDefault();
				inputRef.current.focus();
			}
		},
		[inputRef.current]
	);

	const {
		allDocFx: { group: tocItems }
	} = useStaticQuery(graphql`
		query DocToc {
			allDocFx(filter: { isRoot: { eq: true } }) {
				group(field: namespace) {
					namespace: fieldValue
					nodes {
						id
						slug
						name
					}
				}
			}
		}
	`);

	return (
		<Box display="flex" flexDirection="column">
			<Box px={2}>
				<TextField
					label="Filter"
					fullWidth
					{...{ value, onChange }}
					inputProps={{ ref: inputRef }}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<FilterListIcon />
							</InputAdornment>
						)
					}}
				/>
			</Box>
			<Box
				style={{
					marginTop: "auto",
					maxHeight: `calc(100vh - 96px)`,
					overflowY: "auto"
				}}
			>
				<TocList
					groups={tocItems}
					activeItem={location.pathname}
					filterValue={value}
				/>
			</Box>
		</Box>
	);
};

export default ApiDocToc;

const TocList = ({ groups, activeItem, filterValue }) => (
	<List>
		{groups.map(g => (
			<TocGroup key={g.namespace} group={g} {...{ activeItem, filterValue }} />
		))}
	</List>
);
