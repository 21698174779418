import React, { useRef, useEffect } from "react";
import { Link } from "@runly/gatsby-theme";
import { ListItem, ListItemText } from "@material-ui/core";

const TocItem = ({ selected, filterValue, slug, name, prevName }) => {
	const isOverload =
		name.includes("<") && name.split("<")[0] === prevName?.split("<")[0];

	const el = useRef();
	useEffect(() => {
		if (selected) {
			el?.current?.scrollIntoView?.();
		}
	}, [selected]);

	return (
		<ListItem
			button
			selected={selected}
			component={Link}
			to={slug}
			state={{ filterValue }}
			title={name}
		>
			<ListItemText
				primaryTypographyProps={{
					noWrap: true,
					style: {
						display: "block",
						width: "100%",
						opacity: isOverload ? 0.8 : 1
					}
				}}
				primary={isOverload ? ` …<${name.split("<")[1]}` : name} // eslint-disable-line no-irregular-whitespace
				secondary={<div ref={el} />}
			/>
		</ListItem>
	);
};

export default TocItem;
