import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Link } from "@material-ui/core";

import { Launch as ExternalLinkIcon } from "@material-ui/icons";

const SourceLink = ({ source }) => {
	const {
		site: {
			siteMetadata: { coreRepo }
		}
	} = useStaticQuery(graphql`
		query SourceLinkQuery {
			site {
				siteMetadata {
					coreRepo
				}
			}
		}
	`);

	if (!source) return null;

	const baseUrl = source.path.replace(
		"/github/workspace/",
		`${coreRepo}/blob/master/`
	);
	const url = `${baseUrl}#L${source.startLine + 1}`;

	return (
		<Link href={url} target="_blank">
			View on GitHub <ExternalLinkIcon fontSize="inherit" />
		</Link>
	);
};

export default SourceLink;
