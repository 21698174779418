import React from "react";

import { Mdx } from "@runly/gatsby-theme";

import Xref from "./xref";

const ApiDocMdx = ({ children, components }) => (
	<Mdx components={{ xref: Xref, see: See, ...components }}>{children}</Mdx>
);

const See = ({ cref }) => {
	cref = cref.replace("!:", "");
	return <code>{cref}</code>;
};

export default ApiDocMdx;
