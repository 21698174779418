import React, { Fragment, useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Link } from "@runly/gatsby-theme";

import { Launch as ExternalLinkIcon } from "@material-ui/icons";

import { find, trimEnd } from "lodash";

const extractGenericTypes = name => {
	const idx0 = name.indexOf("{");
	const idx1 = name.lastIndexOf("}");

	if (idx0 < 0 || idx1 < 0) {
		return { nameWithoutTypes: null, types: [] };
	}

	const types = name.substring(idx0 + 1, idx1).split(",");

	const nameWithoutTypes = name.substring(0, idx0);

	return { nameWithoutTypes, types };
};

const useDocFx = href => {
	// ugghhhh: https://github.com/gatsbyjs/gatsby/issues/10482
	// const {
	// 	docFx: { isRoot, name, slug }
	// } = useStaticQuery(graphql`
	// 	query XrefQuery {
	// 		docFx(uid: {eq: "${href}"}) {
	// 			isRoot
	// 			slug
	// 			name
	// 		}
	// 	}
	// `);

	const {
		allDocFx: { nodes: lookup }
	} = useStaticQuery(graphql`
		query XrefQuery {
			allDocFx {
				nodes {
					uid
					isRoot
					slug
					name
				}
			}
		}
	`);

	const result = useMemo(() => {
		let node = find(lookup, { uid: href });
		if (!node && href.indexOf("{") >= 0) {
			// try looking for generic parameters
			const { nameWithoutTypes, types } = extractGenericTypes(href);
			node = find(lookup, { uid: `${nameWithoutTypes}\`${types.length}` });
		}

		return node;
	}, [href, lookup]);

	if (!result)
		return {
			isRoot: false,
			name: href,
			slug: href.startsWith("System.")
				? `https://docs.microsoft.com/dotnet/api/${trimEnd(
						href.toLowerCase(),
						"[]"
				  )}?view=netcore-3.1`
				: null
		};

	return result;
};

const Xref = ({ href }) => {
	let { isRoot, name, slug } = useDocFx(href);

	if (!isRoot && name.indexOf("{") >= 0) {
		const { nameWithoutTypes, types } = extractGenericTypes(name);

		if (!nameWithoutTypes) {
			// just a generic type parameter (don't include the superfluous <>)
			return types.join(",");
		}

		return (
			<code>
				{nameWithoutTypes}
				&lt;
				{types.map((t, i) => (
					<Fragment key={t}>
						<Xref href={t} />
						{i + 1 != types.length ? "," : ""}
					</Fragment>
				))}
				&gt;
			</code>
		);
	}

	if (!isRoot) {
		if (slug) {
			return (
				<Link href={slug} target="_blank">
					<code>{name}</code> <ExternalLinkIcon fontSize="inherit" />
				</Link>
			);
		}

		return <code>{name}</code>;
	}

	return (
		<Link to={slug}>
			<code>{name}</code>
		</Link>
	);
};

export default Xref;
