import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
	ListItem,
	ListItemText,
	Collapse,
	ListItemSecondaryAction,
	IconButton
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import TocItem from "./item";
import { sortBy } from "lodash";

const TocGroup = ({ group, activeItem, filterValue }) => {
	const [open, setOpen] = useState(true);
	const toggleOpen = useCallback(() => {
		setOpen(o => !o);
	}, []);
	const [exited, setExited] = useState(false);
	const handleExited = useCallback(() => {
		setExited(true);
	}, []);
	useEffect(() => {
		if (open) {
			setExited(false);
		}
	}, [open]);
	const filteredItems = useMemo(() => {
		if (exited) return [];
		const filtered = group.nodes.filter(t =>
			t.name.toLowerCase().includes(filterValue.toLowerCase())
		);
		return sortBy(filtered, item => {
			let sortingName = item.name;
			if (item.name.includes("<")) {
				let [pre, post] = item.name.split("<");
				sortingName = `${pre}<${post.length.toString().padStart(5, 0)}`;
			}
			return sortingName;
		});
	}, [exited, filterValue, group.nodes]);
	const classes = useGroupStyles({ open });
	return (
		<ListItem disableGutters>
			<ul style={{ padding: 0, width: "100%", overflow: "hidden" }}>
				<ListItem button onClick={toggleOpen}>
					<ListItemText
						primary={group.namespace}
						primaryTypographyProps={{ color: "textSecondary" }}
					/>
					<ListItemSecondaryAction>
						<IconButton onClick={toggleOpen}>
							<KeyboardArrowDown className={classes.expandedIndicator} />
						</IconButton>
					</ListItemSecondaryAction>
				</ListItem>
				<Collapse in={open} onExited={handleExited}>
					<div>
						{filteredItems.map((t, idx) => (
							<TocItem
								key={t.id}
								selected={activeItem === t.slug}
								filterValue={filterValue}
								prevName={filteredItems[idx - 1]?.name}
								{...t}
							/>
						))}
					</div>
				</Collapse>
			</ul>
		</ListItem>
	);
};

const useGroupStyles = makeStyles(theme => {
	return {
		expandedIndicator: {
			transition: theme.transitions.create("transform"),
			transform: ({ open }) => `rotate(${open ? 180 : 0}deg)`
		}
	};
});

export default TocGroup;
