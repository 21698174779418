import React, { useMemo, useState, useCallback } from "react";
import { graphql } from "gatsby";

import { Typography, Drawer, Box, Button } from "@material-ui/core";
import { Toc as TocIcon } from "@material-ui/icons";

import Layout from "../../layout";
import Seo from "../../seo";

import Doc from "./doc";
import ApiDocToc from "./toc";

import { groupBy } from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import MobileFirstWrapper from "../../mobile-or-desktop";

const ApiDoc = ({ location, data: { docFx: item } }) => {
	const groupedItems = useMemo(() => groupBy(item.items, "type"), [item.items]);
	const classes = useStyles();

	const [tocOpen, setTocOpen] = useState(false);
	const showToc = useCallback(() => {
		setTocOpen(true);
	}, []);
	const hideToc = useCallback(() => {
		setTocOpen(false);
	}, []);

	const toc = <ApiDocToc location={location} />;

	return (
		<Layout
			location={location}
			maxWidth="none"
			appBarClassName={classes.appBar}
			footerClassName={classes.footer}
		>
			<Seo title={`${item.type} ${item.name}`} description={item.summary} />

			<Box display="flex">
				<MobileFirstWrapper
					desktop={
						<Drawer
							variant="permanent"
							anchor="left"
							classes={{ paper: classes.drawerPaper }}
							className={classes.drawer}
						>
							{toc}
						</Drawer>
					}
				>
					<Drawer
						variant="temporary"
						anchor="left"
						open={tocOpen}
						onClose={hideToc}
						classes={{ paper: classes.drawerPaper }}
						className={classes.drawer}
					>
						{toc}
					</Drawer>
				</MobileFirstWrapper>

				<div className={classes.content}>
					<MobileFirstWrapper desktop={" "}>
						<Button onClick={showToc} startIcon={<TocIcon />}>
							Show Table of Contents
						</Button>
					</MobileFirstWrapper>
					<Doc {...item} />

					<Section type="Constructor" groupedItems={groupedItems} />
					<Section type="Field" groupedItems={groupedItems} />
					<Section
						type="Property"
						header="Properties"
						groupedItems={groupedItems}
					/>
					<Section type="Method" groupedItems={groupedItems} />
				</div>
			</Box>
		</Layout>
	);
};

const useStyles = makeStyles(theme => {
	const drawerWidth = theme.spacing(42);
	const lgDrawerWidth = theme.spacing(64);
	const xlDrawerWidth = theme.spacing(80);
	const mdUp = theme.breakpoints.up("md");
	const lgUp = theme.breakpoints.up("lg");
	const xlUp = theme.breakpoints.up("xl");

	const pushoverStyles = {
		[mdUp]: {
			width: `calc(100% - ${drawerWidth}px)`,
			marginLeft: drawerWidth
		},
		[lgUp]: {
			marginLeft: lgDrawerWidth,
			width: `calc(100% - ${lgDrawerWidth}px)`
		},
		[xlUp]: {
			marginLeft: xlDrawerWidth,
			width: `calc(100% - ${xlDrawerWidth}px)`
		}
	};

	return {
		drawer: {
			width: "80vw",
			[mdUp]: {
				width: drawerWidth,
				flexShrink: 0,
				overflow: "hidden"
			},
			[lgUp]: { width: lgDrawerWidth },
			[xlUp]: { width: xlDrawerWidth }
		},
		drawerPaper: {
			width: "80vw",
			[mdUp]: {
				width: drawerWidth,
				overflow: "hidden"
			},
			[lgUp]: { width: lgDrawerWidth },
			[xlUp]: { width: xlDrawerWidth }
		},
		content: {
			flexGrow: 1,
			backgroundColor: theme.palette.background.default,
			padding: theme.spacing(0, 3),
			width: 0
		},
		appBar: pushoverStyles,
		footer: pushoverStyles
	};
});

const Section = ({ type, header, groupedItems }) => {
	const items = groupedItems[type];

	if (!items || !items.length) {
		return null;
	}

	return (
		<section>
			<Typography variant="h4" component="h2" gutterBottom>
				{header || `${type}s`}
			</Typography>

			{items.map(item => (
				<Doc key={item.id} {...item} />
			))}
		</section>
	);
};

export default ApiDoc;

export const pageQuery = graphql`
	query ApiDocById($slug: String!) {
		docFx(slug: { eq: $slug }) {
			slug
			isRoot
			assemblies
			namespace
			derivedClasses
			implements
			inheritance
			name
			type

			code: childDocFxSyntax {
				mdx: childMdx {
					body
				}
			}

			typeParameters: childrenDocFxSyntaxTypeParameters {
				name
				description: childMdx {
					body
				}
			}

			summary: childDocFxSummary {
				mdx: childMdx {
					body
				}
			}
			remarks: childDocFxRemarks {
				mdx: childMdx {
					body
				}
			}

			source {
				path
				startLine
			}

			items: childrenDocFx {
				id
				type
				name
				slug

				code: childDocFxSyntax {
					mdx: childMdx {
						body
					}
				}

				typeParameters: childrenDocFxSyntaxTypeParameters {
					name
					description: childMdx {
						body
					}
				}

				returns: childDocFxSyntaxReturn {
					type
					description: childMdx {
						body
					}
				}

				parameters: childrenDocFxSyntaxParameters {
					name
					type
					description: childMdx {
						body
					}
				}

				summary: childDocFxSummary {
					mdx: childMdx {
						body
					}
				}
				remarks: childDocFxRemarks {
					mdx: childMdx {
						body
					}
				}

				source {
					path
					startLine
				}
			}
		}
	}
`;
