import React from "react";

import {
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { some } from "lodash";

import Xref from "./xref";
import Mdx from "./mdx";

const Syntax = ({ type, typeParameters, returns, parameters }) => {
	return (
		<>
			<SyntaxTable header="Type Parameters" items={typeParameters} />

			<SyntaxTable header="Parameters" items={parameters} />

			<SyntaxTable
				header={
					type == "Field" || type == "Property" ? `${type} Value` : "Returns"
				}
				items={[returns]}
			/>
		</>
	);
};

const SyntaxTable = ({ header, items }) => {
	const classes = useStyles();

	if (!items) return null;

	items = items.filter(i => !!i); // why the fuck are there nulls in here?
	if (!items.length) return null;

	const hasType = some(items, "type");
	const hasName = some(items, "name");

	return (
		<>
			<Typography variant="caption">{header}</Typography>
			<TableContainer component={Paper} className={classes.root}>
				<Table>
					<TableHead>
						<TableRow>
							{hasType ? <TableCell>Type</TableCell> : null}
							{hasName ? <TableCell>Name</TableCell> : null}
							<TableCell>Description</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{items.map(({ type, name, description }) => (
							<TableRow key={`${type}-${name}`}>
								{hasType ? (
									<TableCell component="th" scope="row">
										<Xref href={type} />
									</TableCell>
								) : null}
								{hasName ? (
									<TableCell>
										<code>{name}</code>
									</TableCell>
								) : null}
								<TableCell>
									<Mdx components={{ p: CellContents }}>{description.body}</Mdx>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

const CellContents = props => <div {...props} />;

const useStyles = makeStyles(theme => ({
	root: {
		margin: theme.spacing(1, 0, 3, 0)
	}
}));

export default Syntax;
