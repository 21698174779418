import React from "react";

import { Typography, Divider, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Mdx from "./mdx";
import SourceLink from "./source-link";
import Syntax from "./syntax";

const Doc = ({
	type,
	slug,
	isRoot,
	name,
	summary,
	namespace,
	assemblies,
	code,
	typeParameters,
	parameters,
	returns,
	remarks,
	source
}) => {
	const classes = useStyles();

	return (
		<article className={classes.root}>
			<Typography
				id={!isRoot ? slug : null}
				variant={isRoot ? "h3" : "h5"}
				component={isRoot ? "h1" : "h3"}
				noWrap
			>
				{isRoot ? `${type} ` : null}
				{isRoot ? (
					<code>{name}</code>
				) : (
					<Link href={`#${slug}`} color="inherit" underline="none">
						<code>{name}</code>
					</Link>
				)}
			</Typography>

			{summary ? (
				<Typography variant="subtitle1" component="div">
					<Mdx>{summary.mdx.body}</Mdx>
				</Typography>
			) : null}

			{isRoot ? (
				<Typography paragraph>
					Namespace: <code>{namespace}</code>
					<br />
					Assembly: <code>{assemblies[0]}</code>
				</Typography>
			) : null}

			<Typography paragraph component="div">
				<Typography variant="caption">
					Declaration <SourceLink source={source} />
				</Typography>
				<Mdx>{code.mdx.body}</Mdx>
			</Typography>

			{remarks ? (
				<Typography paragraph component="div">
					<Typography variant="caption">Remarks</Typography>
					<br />
					<Mdx>{remarks.mdx.body}</Mdx>
				</Typography>
			) : null}

			<Syntax {...{ type, typeParameters, parameters, returns }} />

			<Divider />
		</article>
	);
};

const useStyles = makeStyles(theme => ({
	root: {
		margin: theme.spacing(1, 0, 3, 0)
	}
}));

export default Doc;
